<template>
  <v-container class="fill-height"  fluid>
    <v-row align="center" justify="center" class="text-center">
      <v-col class="col-md-4">
        <v-card :dark="$vuetify.theme.dark" class="mt-5 text-center" color="primary_card" :loading="loader">
          <v-card-title class="justify-center pt-16">
            <h2 class=" text-center"> {{ $t('nga911')}}</h2>
          </v-card-title>
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="pa-16 pt-4"
          >
            <v-card-text>
              <v-text-field
                cypress="email"
                id="email"
                @keyup.enter="auth"
                v-model="email"
                :label="$t('email')"
                :rules="validation.emailRules"
                required
                outlined
                prepend-inner-icon="mdi-email"
                class="login-email"
              ></v-text-field>
              <v-text-field
                cypress="password"
                id="password"
                @keyup.enter="auth"
                v-model="password"
                :label="$t('password')"
                :rules="validation.passwordRules"
                required
                outlined
                prepend-inner-icon="mdi-key"
                class="login-pass"
                :append-icon="isPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="isPasswordHidden = !isPasswordHidden"
                :type="isPasswordHidden ? 'password' : 'text'"
              ></v-text-field>

              <div id="auth">
                <v-btn color="red" :disabled="loader"
                       cypress="auth"
                       @click="auth"
                       large
                       class="ma-4 font-weight-bold px-4"
                >

                   {{ $t('authorization') }}
                </v-btn>
              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapActions } from 'vuex'
import router from '@/router'

export default {
  name: 'LoginPage',

  data: (vm) => ({
    isPasswordHidden: true,
    email: '',
    password: '',
    loader: false,
    valid: true,

    validation: {
      emailRules: [
        v => !!v || vm.$t('e_mail_is_required'),
        v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || vm.$t('e_mail_must_be_valid')
      ],
      passwordRules: [
        v => !!v || vm.$t('password_is_required'),
        v => v.length >= 3 || vm.$t('password_must_be_greater_than_3_characters')
      ]
    }
  }),

  methods: {

    ...mapActions('global', ['authorizeAction']),
    ...mapActions('notifications', ['notificationSnackBarAction']),

    async auth (skipValidation = false) {
      if (!skipValidation) {
        await this.$refs.form.validate()

        if (!this.valid) {
          return -1
        }
      }

      this.loader = true

      const authParams = {
        email: this.email,
        password: this.password
      }

      await this.authorizeAction(authParams).then(async (response) => {
        if (response.data && response.data.id) {
          this.loader = false

          await this.notificationSnackBarAction({
            text: response.data.message,
            color: 'success',
            show: true
          })

          return false
        }

        await router.push({ name: 'Dashboard' })
      }).catch((error) => {
        this.loader = false

        if (error.response) {
          this.notificationSnackBarAction({
            text: error.response.data.message || this.$t('wrong_credentials'),
            color: 'warning',
            show: true
          })
        } else {
          this.notificationSnackBarAction({
            text: this.$t('something_went_wrong_please_try_again_later'),
            color: 'error',
            show: true
          })
        }
      })
    }
  }
}
</script>
